// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.dev.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  version: '3.1.1',
  apiURL: 'https://api.imcas.com/v1',
  apiURLV2: 'https://api.imcas.com/v2',
  apiURLNew: 'https://api2.imcas.com/',
  apiDomain: 'https://api.imcas.com',
  phonenumber: '+331-40-73-82-82',
  pusher: {
    key: '302b9ec09ce0e35846cd'
  },
  algolia: {
    appId: 'UUAJWCXCTX',
    apiKey: '20765c03e2b6eb49d0cfa979acf764b5'
  },
  cdn_base_url: 'https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com',
  new_cdn_base_url: 'https://assets.imcas.com',

  emailSupport: 'support@imcas.com',
  domain: 'aesthetics',
  congress_id: 68,
  base_url: 'https://www.imcas.com',
  mobile: false
};
